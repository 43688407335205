/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet as TranslatedHelmet } from "gatsby-plugin-react-i18next"
import { Helmet as NonTranslatedHelmet, HelmetProps } from "react-helmet"
import addPageToMeta from "../helpers/add-page-to-meta"
import PageMetadata from "./seo/page-metadata.interface"
import { freshdeskScript, freshdeskSrc } from "../helpers/freshdesk-script"
import { AppFunctionComponent } from "../types"
import useTranslationHook from "../hooks/use-translation.hook"
import { BlogPost } from "./community/blog/blog.interface"
import { getSrc } from "gatsby-plugin-image"

type MetaProps = JSX.IntrinsicElements["meta"]
interface CustomHelmetProps {
  canonical: string | undefined
}
interface Props {
  lang?: string
  canonical?: string
  meta?: MetaProps[]
  post?: BlogPost
  pagination?: {
    currentPage: number
    prevPage: number | null
    nextPage: number | null
    pagePrefix: string
  }
}

interface SeoQueryResult {
  site: {
    siteMetadata: {
      title: string
      description: string
      author: string
      siteUrl: string
    }
  }
  defaultPageMeta: PageMetadata
}

const SEO: AppFunctionComponent<Props & PageMetadata> = ({
  lang = "en",
  canonical,
  meta = [],
  post,
  pagination,
  ...pageMeta
}) => {
  const { site, defaultPageMeta } = useStaticQuery<SeoQueryResult>(
    graphql`
      query DefaultMeta {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        defaultPageMeta: contentfulPageMeta(page: { eq: "default" }) {
          ...PageMeta
        }
      }
    `
  )

  const { language } = useTranslationHook()

  const metaDescription =
    pageMeta.description ||
    defaultPageMeta.description ||
    site.siteMetadata.description

  const {
    title,
    facebookTitle,
    facebookDescription,
    twitterDescription,
    twitterTitle,
    facebookImage,
    twitterImage,
    description,
  } = addPageToMeta(
    { ...pageMeta, description: metaDescription },
    pagination?.currentPage
  )

  const facebookMetaImage = facebookImage || defaultPageMeta.facebookImage
  const twitterMetaImage =
    twitterImage ||
    facebookImage ||
    defaultPageMeta.twitterImage ||
    defaultPageMeta.facebookImage

  const links = [
    ...(canonical
      ? [
          {
            rel: "canonical",
            href: canonical,
          },
        ]
      : []),
    ...(pagination?.nextPage
      ? [
          {
            rel: "next",
            href: `${site.siteMetadata.siteUrl}${pagination.pagePrefix}${pagination.nextPage}`,
          },
        ]
      : []),
    ...(pagination?.prevPage
      ? [
          {
            rel: "prev",
            href: `${site.siteMetadata.siteUrl}${pagination.pagePrefix}${pagination?.prevPage}`,
          },
        ]
      : []),
  ]
  return (
    // @ts-ignore Unknown issue with the constructor of Helmet.
    <CustomHelmet
      canonical={canonical}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultPageMeta.title || site.siteMetadata.title}`}
      link={links}
      meta={[
        {
          name: `description`,
          content: description || defaultPageMeta.description,
        },
        {
          property: `og:title`,
          content:
            facebookTitle ||
            title ||
            defaultPageMeta.facebookTitle ||
            defaultPageMeta.title,
        },
        {
          property: `og:description`,
          content:
            facebookDescription ||
            description ||
            defaultPageMeta.facebookDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: twitterMetaImage ? `summary_large_image` : `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content:
            twitterTitle ||
            title ||
            defaultPageMeta.twitterTitle ||
            defaultPageMeta.title,
        },
        {
          name: `twitter:description`,
          content:
            twitterDescription ||
            description ||
            defaultPageMeta.twitterDescription,
        },
        {
          name: "p:domain_verify",
          content: process.env.GATSBY_PINTEREST_VERIFY,
        },
        {
          name: "facebook-domain-verification",
          content: process.env.FACEBOOK_DOMAIN_VERIFY_KEY,
        },
        ...(facebookMetaImage
          ? [
              {
                property: `og:image`,
                content: facebookMetaImage.gatsbyImageData.images.fallback?.src,
              },
              {
                property: `og:image:width`,
                content: facebookMetaImage.gatsbyImageData.width.toString(),
              },
              {
                property: `og:image:height`,
                content: facebookMetaImage.gatsbyImageData.height.toString(),
              },
            ]
          : []),
        ...(twitterMetaImage
          ? [
              {
                name: `twitter:image:src`,
                content: twitterMetaImage.gatsbyImageData.images.fallback?.src,
              },
            ]
          : []),
        // @ts-ignore
      ].concat(meta)}
    >
      {post && (
        <script type="application/ld+json">
          {`{
          "@type": "BlogPosting",
           "@context": "https://schema.org",
           "headline": "${post.postTitle}",
           "name": "${post.postTitle}",
           "description": "${metaDescription}",
           "url": "${site.siteMetadata.siteUrl}/community/blog/${
            post.postSlug
          }/",
           "image": [
             {
               "@type": "ImageObject",
               "@id": "${getSrc(post.postFeaturedImage.gatsbyImageData)}",
               "url": "${getSrc(post.postFeaturedImage.gatsbyImageData)}",
               "height": "${post.postFeaturedImage.gatsbyImageData.height}",
               "width": "${post.postFeaturedImage.gatsbyImageData.width}"
              }
            ],
            "datePublished": "${post.postOriginalPublishDate}",
            "dateModified": "${post.postOriginalPublishDate}",
            "author": [
              {
                "@type": "Organization",
                "name": "${site.siteMetadata.title}",
                "url": "${site.siteMetadata.siteUrl}"
              }
            ],
            "isPartOf": {
              "@type": "Blog",
              "@id": "${site.siteMetadata.siteUrl}/community/blog/",
              "name": "${site.siteMetadata.title} Blog",
              "publisher": {
                "@type": "Organization",
                "@id": "${site.siteMetadata.siteUrl}",
                "name": "${site.siteMetadata.title}"
              }
              ${post.postTags ? "}," : "}"}
              ${
                post.postTags &&
                `"keywords": [
                  ${post.postTags.map((el) => `"${el.text}"`).join(",")}
                ]`
              }
            }`}
        </script>
      )}
      <script type="text/javascript" async defer>
        {freshdeskScript(language)}
      </script>

      <script>
        {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '758375772942853');
fbq('track', 'PageView');
`}
      </script>

      <noscript>
        {`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=758375772942853&ev=PageView&noscript=1"
        />
        `}
      </noscript>
      <script type="text/javascript" src={freshdeskSrc} async defer />
    </CustomHelmet>
  )
}

const CustomHelmet: AppFunctionComponent<HelmetProps & CustomHelmetProps> = ({
  children,
  canonical,
  ...rest
}) => {
  return canonical?.includes("/blog") ? (
    <NonTranslatedHelmet {...rest}>{children}</NonTranslatedHelmet>
  ) : (
    <TranslatedHelmet {...rest}>{children}</TranslatedHelmet>
  )
}

export default SEO
